<template>
  <div class="partners">
    <top-bar />
    <page-title title="Партнерам" description="Какоето описание" />
    <div class="container">
      <div class="info">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam dolores dolorum ducimus facilis fuga fugit illo
        optio provident rerum vel. Ad consequuntur, doloremque doloribus est nemo nostrum officiis quis voluptatem?
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
}
</script>

<style scoped lang="scss">
.partners{
  .info{
    font-size: 14px;
    color: #000;
  }
}
</style>
